<template>
  <div class="app-container">
    <div class="page-title" />
    <el-card class="box-card">
      <article-detail :is-edit="true" />
    </el-card>
  </div>
</template>

<script>
import ArticleDetail from './components/ArticleDetail';

export default {
  name: 'EditForm',
  components: { ArticleDetail },
};
</script>

